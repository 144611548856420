<template>
  <BaseWidget :item="item">
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <!-- Rendu des documents en provenance de Strapi -->
    <ContentSliderComponent v-if="item.source === 'Strapi'" :items="documents">
      <template v-slot="{ item }">
        <b-card
          class="document-card-widget document-card-strapi"
          :img-src="getAssetUrl(item.image)"
          img-top
        >
          <b-card-title>
            <b-link target="_blank" :href="getAssetUrl(item.url)">
              <b-icon-file-pdf class="icon-pdf"></b-icon-file-pdf>
              {{ item.titre }}
            </b-link>
          </b-card-title>
          <b-card-text>
            <em>Publi&eacute; le {{ datePublication(item) }}</em>
          </b-card-text>
          <template #footer v-if="item.route">
            <b-link :to="item.route">
              <b-button pill variant="primary">Voir tout</b-button>
            </b-link>
          </template>
        </b-card>
      </template>
    </ContentSliderComponent>
    <!-- Rendu des documents en provenance de Kerhis -->
    <ContentSliderComponent v-if="item.source === 'Kerhis'" :items="documents">
      <template v-slot="{ item }">
        <b-card class="document-card-widget document-card-kerhis">
          <b-card-title>
            <DownloadComponent
              :typeDocument="item.type"
              :targetId="item.id"
              v-slot="{ locked }"
            >
              <b-icon-file-earmark-lock2
                v-if="locked"
                class="icon-pdf"
              ></b-icon-file-earmark-lock2>
              <b-icon-file-earmark-pdf
                v-else
                class="icon-pdf"
              ></b-icon-file-earmark-pdf>
              {{ item.titre }}
            </DownloadComponent>
          </b-card-title>
          <b-card-text>
            <em>Publi&eacute; le {{ datePublication(item) }}</em>
          </b-card-text>
          <template #footer v-if="item.type.route">
            <b-link :to="{ name: item.type.route }">
              <b-button pill variant="primary">Voir tout</b-button>
            </b-link>
          </template>
        </b-card>
      </template>
    </ContentSliderComponent>
  </BaseWidget>
</template>

<script>
import BaseWidget from "./BaseWidget.vue";
import ErrorComponent from "../ErrorComponent.vue";
import ContentSliderComponent from "../controls/ContentSliderComponent.vue";
import DownloadComponent from "../controls/DownloadComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "DocumentsWidget",
  props: ["item"],
  components: {
    BaseWidget,
    ErrorComponent,
    ContentSliderComponent,
    DownloadComponent,
  },
  data() {
    return {
      documents: [],
      error: null,
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    datePublication(doc) {
      return new Date(doc.publishedAt).toLocaleDateString();
    },
    getAssetUrl(url) {
      return StrapiService.getAssetUrl(url);
    },
    load() {
      let promise =
        this.item.source === "Strapi"
          ? StrapiService.getDerniersDocuments(
              this.exploitationCourante.codeSociete,
              this.item.limite
            )
          : ExploitationService.getDerniersDocuments(
              this.exploitationCourante.codeTiers,
              this.item.limite
            ).then((response) =>
              response.data.map((d, i) => {
                d.index = i;
                d.type = this.$store.state.expl.typesDocument[d.type];
                d.publishedAt = d.dateHeureIndexation;
                return d;
              })
            );
      promise
        .then((results) => (this.documents = results))
        .catch((error) => (this.error = UtilsService.handleError(error)));
    },
  },
};
</script>
