<template>
  <BaseWidget :item="item">
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <div class="clearfix" v-if="indicateurs">
      <div class="float-left widget-chart mr-0 mr-md-5">
        <!-- Pie chart livré/attendu -->
        <h4>Collecte R{{ recolteEnCoursDeCollecte % 100 }}</h4>
        <Pie :chart-data="pieChartData" />
      </div>
      <div class="float-left widget-chart mt-4 mt-md-0">
        <!-- Bar chart déclaré/réel -->
        <h4>Ecarts de collecte</h4>
        <Bar :chart-data="barChartData" />
      </div>
    </div>
  </BaseWidget>
</template>

<script>
import { Bar, Pie } from "vue-chartjs/legacy";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import BaseWidget from "./BaseWidget.vue";
import ErrorComponent from "../ErrorComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "IndicateursWidget",
  props: ["item"],
  components: { Bar, Pie, BaseWidget, ErrorComponent },
  data() {
    return {
      indicateurs: null,
      error: null,
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    recolteEnCoursDeCollecte() {
      return this.$store.getters["ref/recolteEnCoursDeCollecte"] ?? 2022;
    },
    pieChartData() {
      let c = this.indicateurs.collectes.find(
        (c) => c.codeRecolte == this.recolteEnCoursDeCollecte
      );
      return {
        labels: ["Apporté (t) ", "Attendu (t) "],
        datasets: [
          {
            label: `Campagne ${this.recolteEnCoursDeCollecte}`,
            data: [c?.quantiteLivree ?? 0.0, c?.quantiteAttendue ?? 0.0],
            backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
            hoverOffset: 4,
          },
        ],
      };
    },
    barChartData() {
      return {
        labels: this.indicateurs.collectes.map(
          (c) => `R${c.codeRecolte % 100}`
        ),
        datasets: [
          {
            label: "Déclaré (t) ",
            data: this.indicateurs.collectes.map((c) => c.quantiteDeclaree),
            backgroundColor: ["rgb(75, 192, 192)"],
          },
          {
            label: "Réel (t) ",
            data: this.indicateurs.collectes.map(
              (c) => c.quantiteLivree + c.quantiteAttendue
            ),
            backgroundColor: ["rgb(255, 99, 132)"],
          },
        ],
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      ExploitationService.getIndicateurs(this.exploitationCourante?.codeTiers)
        .then((response) => {
          response.data.collectes.sort(
            UtilsService.sortByNumberProperty("codeRecolte")
          );
          this.indicateurs = response.data;
        })
        .catch((error) => (this.error = UtilsService.handleError(error)));
    },
  },
};
</script>
