<template>
  <BaseWidget v-if="apporteur" :item="item" @mouseenter.native="stopTimer">
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <b-row v-if="results.length > 0">
      <b-col cols="12" md="4">
        <div class="widget-chart">
          <Doughnut :chart-data="chartData" />
        </div>
      </b-col>
      <b-col cols="12" md="8" class="mt-4 mt-md-0">
        <h4>
          Campagne
          <b-form-select
            v-if="recoltes.length > 1"
            style="width: unset"
            content-cols="auto"
            :options="recoltes.map((r, i) => ({ value: i, text: r }))"
            v-model="index"
          ></b-form-select>
          <template v-else>{{ codeRecolte }}</template>
          :
          <span class="h1 font-weight-bold">
            {{ pourcentageAvancement }}
            %
          </span>
        </h4>
        <div class="mt-3">
          <span v-for="param in anomaliesParam" :key="param.codeAnomalie">
            <b-link :to="{ name: param.route, params: { codeRecolte } }">
              <b-icon-check-circle-fill
                v-if="!anomalies[param.codeAnomalie]"
                variant="success"
              ></b-icon-check-circle-fill>
              <b-icon-question-circle-fill
                v-else-if="param.variant === 'warning'"
                variant="warning"
              ></b-icon-question-circle-fill>
              <b-icon-x-circle-fill
                v-else
                variant="danger"
              ></b-icon-x-circle-fill>
              &nbsp;
              <strong>{{ anomalies[param.codeAnomalie] || 0 }}</strong>
              {{ param.text }}
            </b-link>
            <br />
          </span>
        </div>
        <b-button
          class="widget-button"
          pill
          variant="outline-primary"
          @click="$router.push({ name: 'recolte', params: { codeRecolte } })"
        >
          Acc&eacute;der &agrave; la campagne
          <b-icon-arrow-right></b-icon-arrow-right>
        </b-button>
      </b-col>
    </b-row>
  </BaseWidget>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import BaseWidget from "./BaseWidget.vue";
import ErrorComponent from "../ErrorComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "DeclarationsWidget",
  props: ["item"],
  components: { Doughnut, BaseWidget, ErrorComponent },
  data: () => ({
    error: null,
    results: [],
    index: 0,
    timer: null,
    anomaliesParam: [
      {
        codeAnomalie: "EMBLAVEMENT_NON_SAISI",
        text: "emblavement(s) à saisir",
        variant: "danger",
        route: "emblavements",
      },
      {
        codeAnomalie: "PARCELLE_NON_SEMEE",
        text: "parcelle(s) à semer",
        variant: "warning",
        route: "emblavements",
      },
      {
        codeAnomalie: "PARCELLE_NON_MOISSONNEE",
        text: "parcelle(s) à récolter",
        variant: "warning",
        route: "moisson",
      },
      {
        codeAnomalie: "AUTOCONSO_NON_SAISIE",
        text: "autoconsommation(s) à saisir",
        variant: "danger",
        route: "autoconsommation",
      },
      {
        codeAnomalie: "QUANTITE_INVALIDE",
        text: "quantité(s) autoconsommée(s) à rectifier",
        variant: "danger",
        route: "autoconsommation",
      },
      {
        codeAnomalie: "ENLEVEMENT_NON_SAISI",
        text: "enlèvement(s) à saisir",
        variant: "danger",
        route: "enlevements",
      },
      {
        codeAnomalie: "MODE_INVALIDE",
        text: "mode(s) d'enlèvement à rectifier",
        variant: "danger",
        route: "enlevements",
      },
      {
        codeAnomalie: "DATE_INVALIDE",
        text: "date(s) d'enlèvement à rectifier",
        variant: "danger",
        route: "enlevements",
      },
    ],
  }),
  computed: {
    recoltes() {
      let recolteEnCoursDeSaisie =
        this.$store.getters["ref/recolteEnCoursDeSaisie"];
      return this.$store.state.ref.recoltes
        .map((r) => r.codeRecolte)
        .filter((c) => c >= recolteEnCoursDeSaisie)
        .reverse();
    },
    codeRecolte() {
      return this.results[this.index]?.codeRecolte;
    },
    codeTiers() {
      return (
        this.$store.state.expl.adherentCourant ??
        this.$store.state.expl.exploitationCourante
      );
    },
    nbParcelles() {
      return this.results[this.index]?.nbParcelles;
    },
    apporteur() {
      // Est considéré apporteur toute exploitation
      // qui a au moins 1 parcelle sur la campagne en cours
      return this.results.find((r) => r.nbParcelles > 0);
    },
    anomalies() {
      return this.results[this.index]?.anomalies;
    },
    repartitionAnomalies() {
      // Répartition des anomalies par sévérité
      let repartition = {
        success: 0,
        warning: 0,
        danger: 0,
      };
      for (const p of this.anomaliesParam) {
        if (p.variant === "warning") {
          repartition.warning += this.anomalies[p.codeAnomalie] ?? 0;
        } else {
          repartition.danger += this.anomalies[p.codeAnomalie] ?? 0;
        }
      }
      // Calcul des saisies OK
      repartition.success =
        this.nbParcelles * 3 - repartition.warning - repartition.danger;
      return repartition;
    },
    chartData() {
      return {
        labels: ["Saisi", "A valider", "A corriger"],
        datasets: [
          {
            label: `Campagne ${this.codeRecolte}`,
            data: [
              this.repartitionAnomalies.success || 1,
              this.repartitionAnomalies.warning,
              this.repartitionAnomalies.danger,
            ],
            backgroundColor: ["#00805A", "#F8BB67", "#B94245"],
            hoverOffset: 4,
          },
        ],
      };
    },
    pourcentageAvancement() {
      if (this.nbParcelles <= 0) {
        return 100;
      }
      return Math.round(
        ((this.nbParcelles * 3 - this.repartitionAnomalies.danger) /
          (this.nbParcelles * 3)) *
          100
      );
    },
  },
  mounted() {
    this.load();
    if (this.recoltes.length > 1) {
      this.timer = setInterval(() => {
        this.index = (this.index + 1) % 2;
      }, 10000);
    }
  },
  methods: {
    async load() {
      try {
        let results = await Promise.all(
          this.recoltes.map((codeRecolte) =>
            ExploitationService.getEtatDeclarations(codeRecolte, this.codeTiers)
          )
        );
        this.results = results.map((response) => response.data);
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>
