<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <path
      d="M23 26V24C23 22.9391 22.5786 21.9217 21.8284 21.1716C21.0783 20.4214 20.0609 20 19 20H11C9.93913 20 8.92172 20.4214 8.17157 21.1716C7.42143 21.9217 7 22.9391 7 24V26"
      fill="#ACD1C6"
    />
    <path
      d="M23 26V24C23 22.9391 22.5786 21.9217 21.8284 21.1716C21.0783 20.4214 20.0609 20 19 20H11C9.93913 20 8.92172 20.4214 8.17157 21.1716C7.42143 21.9217 7 22.9391 7 24V26"
      stroke="#054643"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 16C17.2091 16 19 14.2091 19 12C19 9.79086 17.2091 8 15 8C12.7909 8 11 9.79086 11 12C11 14.2091 12.7909 16 15 16Z"
      fill="#ACD1C6"
      stroke="#054643"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29 25.9999V23.9999C28.9993 23.1136 28.7044 22.2527 28.1614 21.5522C27.6184 20.8517 26.8581 20.3515 26 20.1299"
      stroke="#054643"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 8.12988C22.8604 8.35018 23.623 8.85058 24.1676 9.55219C24.7122 10.2538 25.0078 11.1167 25.0078 12.0049C25.0078 12.8931 24.7122 13.756 24.1676 14.4576C23.623 15.1592 22.8604 15.6596 22 15.8799"
      stroke="#054643"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g clip-path="url(#clip0_1052_23606)">
      <path
        d="M36.1666 15.05V16.3C36.1671 16.416 36.1433 16.5309 36.0968 16.6372C36.0503 16.7435 35.9821 16.839 35.8966 16.9174C35.8111 16.9959 35.7102 17.0556 35.6002 17.0928C35.4903 17.1299 35.3738 17.1437 35.2583 17.1333C33.9761 16.994 32.7445 16.5559 31.6624 15.8541C30.6557 15.2144 29.8022 14.3609 29.1624 13.3541C28.4583 12.2671 28.02 11.0295 27.8833 9.74163C27.8729 9.6264 27.8865 9.51028 27.9235 9.40064C27.9604 9.291 28.0198 9.19025 28.0978 9.1048C28.1757 9.01936 28.2707 8.95109 28.3765 8.90434C28.4823 8.8576 28.5967 8.8334 28.7124 8.83329H29.9624C30.1646 8.8313 30.3607 8.90291 30.514 9.03476C30.6673 9.16662 30.7675 9.34973 30.7958 9.54996C30.8485 9.94999 30.9464 10.3428 31.0874 10.7208C31.1435 10.8699 31.1556 11.032 31.1224 11.1878C31.0892 11.3436 31.012 11.4867 30.8999 11.6L30.3708 12.1291C30.9639 13.1723 31.8276 14.036 32.8708 14.6291L33.3999 14.1C33.5132 13.9879 33.6562 13.9107 33.8121 13.8775C33.9679 13.8443 34.13 13.8564 34.2791 13.9125C34.6571 14.0535 35.0499 14.1514 35.4499 14.2041C35.6523 14.2327 35.8372 14.3346 35.9693 14.4906C36.1015 14.6465 36.1717 14.8456 36.1666 15.05Z"
        fill="#ACD1C6"
        stroke="#054643"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1052_23606">
        <rect width="10" height="10" fill="white" transform="translate(27 8)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ContactsWidgetIcon",
};
</script>
