<template>
  <BaseWidget :item="item">
    <div>
      <StrapiMarkdownComponent :item="item.contenu"></StrapiMarkdownComponent>
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from "./BaseWidget.vue";
import StrapiMarkdownComponent from "../strapi/StrapiMarkdownComponent.vue";
export default {
  name: "LibreWidget",
  props: ["item"],
  components: { BaseWidget, StrapiMarkdownComponent },
};
</script>
