<template>
  <StandardIconBloc
    :icon="item.icone || 'exclamation-triangle'"
    :backColor="item.backcolor"
    :frontColor="item.frontcolor"
    style="margin-bottom: 0; border-radius: 0"
  >
    <b-link v-if="link" :to="link">
      <StrapiMarkdownComponent :item="item.contenu"></StrapiMarkdownComponent>
    </b-link>
    <template v-else>
      <StrapiMarkdownComponent :item="item.contenu"></StrapiMarkdownComponent>
    </template>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "../blocs/StandardIconBloc.vue";
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
export default {
  name: "StrapiAlerteComponent",
  props: ["item"],
  components: { StandardIconBloc, StrapiMarkdownComponent },
  computed: {
    link() {
      if (this.item.url) {
        return this.item.url;
      }
      if (this.item.page?.data?.id) {
        return { name: "page", params: { id: this.item.page.data.id } };
      }
      return null;
    },
  },
};
</script>
