<template>
  <svg
    width="40"
    height="42"
    viewBox="0 0 40 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="21.5376" r="20" fill="#ACD1C6" />
    <path
      d="M30.0577 25.7952L30.0946 25.9443L30.2088 26.047C30.3783 26.1994 30.5 26.4484 30.5 26.7344V27.6406C30.5 28.1652 30.1294 28.5 29.7679 28.5H12.9286C10.5288 28.5 8.5 26.3357 8.5 23.5625V5.4375C8.5 2.66432 10.5288 0.5 12.9286 0.5H29.7679C30.1294 0.5 30.5 0.83483 30.5 1.35938V20.3906C30.5 20.6778 30.3777 20.9239 30.2131 21.0685L30.0953 21.172L30.0577 21.3242C29.9339 21.8248 29.8814 22.7184 29.8814 23.5597C29.8814 24.4009 29.9339 25.2945 30.0577 25.7952ZM27.5808 25.875H28.1337L28.0783 25.3249C27.9841 24.3897 27.9841 22.7353 28.0783 21.8001L28.1337 21.25H27.5808H12.9286C11.7038 21.25 10.7857 22.3368 10.7857 23.5625C10.7857 24.7938 11.6986 25.875 12.9286 25.875H27.5808ZM14.8795 6.75C14.3888 6.75 14.0714 7.17415 14.0714 7.58984V8.72266C14.0714 9.13835 14.3888 9.5625 14.8795 9.5625H25.7634C26.2541 9.5625 26.5714 9.13834 26.5714 8.72266V7.58984C26.5714 7.17416 26.2541 6.75 25.7634 6.75H14.8795ZM14.8795 10.375C14.3888 10.375 14.0714 10.7992 14.0714 11.2148V12.3477C14.0714 12.7633 14.3888 13.1875 14.8795 13.1875H25.7634C26.2541 13.1875 26.5714 12.7633 26.5714 12.3477V11.2148C26.5714 10.7992 26.2541 10.375 25.7634 10.375H14.8795Z"
      fill="white"
      stroke="#054643"
    />
  </svg>
</template>

<script>
export default {
  name: "DocumentsWidgetIcon",
};
</script>
