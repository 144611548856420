<template>
  <BaseWidget :item="item">
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <b-row>
      <b-col cols="12" md="4">
        <h4>Solde</h4>
        <div>
          <template v-if="computedSolde > 0">
            Votre coop&eacute;rative doit
          </template>
          <template v-else-if="computedSolde < 0">
            Votre exploitation doit
          </template>
          <h1 class="font-weight-bold">
            <MontantComponent
              :value="computedSolde"
              :options="{
                style: 'currency',
                currency: 'EUR',
                signDisplay: 'never',
              }"
              variantPositive="success"
              variantNegative="danger"
            ></MontantComponent>
          </h1>
          <template v-if="computedSolde > 0">
            &agrave; votre exploitation.
          </template>
          <template v-else-if="computedSolde < 0">
            &agrave; votre coop&eacute;rative.
          </template>
        </div>
        <b-button
          class="widget-button"
          pill
          variant="outline-primary"
          @click="$router.push({ name: 'releve-compte' })"
        >
          Voir mon relev&eacute; de compte
          <b-icon-arrow-right></b-icon-arrow-right>
        </b-button>
      </b-col>
      <b-col cols="12" md="8" class="mt-4 mt-md-0">
        <h4>Derniers documents</h4>
        <section v-if="factures.length === 0">
          Il n'y a aucun document dans votre relev&eacute; de compte.
        </section>
        <section v-for="doc in factures" :key="doc.index">
          <DownloadComponent
            :typeDocument="doc.type"
            :targetId="doc.id"
            v-slot="{ locked }"
          >
            <b-icon-file-earmark-lock2
              v-if="locked"
              class="icon-pdf"
            ></b-icon-file-earmark-lock2>
            <b-icon-file-earmark-pdf
              v-else
              class="icon-pdf"
            ></b-icon-file-earmark-pdf>
            {{ doc.titre }}
            <em>&eacute;mis le {{ datePublication(doc) }}</em>
          </DownloadComponent>
        </section>
      </b-col>
    </b-row>
  </BaseWidget>
</template>

<script>
import BaseWidget from "./BaseWidget.vue";
import ErrorComponent from "../ErrorComponent.vue";
import MontantComponent from "../MontantComponent.vue";
import DownloadComponent from "../controls/DownloadComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ComptaWidget",
  props: ["item"],
  components: {
    BaseWidget,
    ErrorComponent,
    MontantComponent,
    DownloadComponent,
  },
  data() {
    return {
      error: null,
      solde: 0.0,
      factures: [],
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    computedSolde() {
      return this.solde ?? 0.0;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    datePublication(doc) {
      return new Date(doc.publishedAt).toLocaleDateString();
    },
    async load() {
      try {
        let response = await ExploitationService.getSituationFinanciere(
          this.exploitationCourante.codeTiers,
          this.item.limite
        );
        this.solde = response.data.solde;
        this.factures = response.data.factures.map((d, i) => {
          d.index = i;
          d.type = this.$store.state.expl.typesDocument[d.type];
          d.publishedAt = d.dateHeureIndexation;
          return d;
        });
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
  },
};
</script>
