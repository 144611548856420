<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="21" r="20" fill="#ACD1C6" />
    <path
      d="M18.8975 21.5102C18.7654 21.513 18.6411 21.5344 18.5261 21.5682L18.8975 21.5102ZM18.8975 21.5102C18.6361 21.6859 18.411 21.8582 18.2232 22.0281C18.2233 22.028 18.2234 22.028 18.2235 22.0279L18.8975 21.5102ZM22.9114 19.6965C23.9753 19.6341 24.9161 19.9286 25.6916 20.2817L25.6916 20.2817C26.2548 20.5381 26.796 20.8594 27.2303 21.1172L27.2414 21.1238L27.2415 21.1239C27.3826 21.2077 27.5114 21.2842 27.6288 21.3516C27.9059 21.5105 28.1056 21.6102 28.2505 21.6692C28.3222 21.6983 28.3745 21.7151 28.4114 21.7242C28.447 21.7331 28.462 21.7333 28.4617 21.7333H28.9615V22.2333V24.1667V24.6667H28.4615C27.6205 24.6667 26.8305 24.23 26.3087 23.9307L26.3086 23.9306C26.1403 23.834 25.9779 23.7377 25.8209 23.6445L18.526 21.5682C17.9803 21.7284 17.5441 22.1939 17.3943 22.6849L17.2405 23.1893L16.745 23.0088L15.0665 22.3975L14.6211 22.2352L14.7594 21.7818C15.1995 20.3389 16.356 19.1613 17.7854 18.7416C19.1513 18.3406 20.6703 18.6536 21.9764 19.8431C22.2897 19.7626 22.6016 19.7146 22.9114 19.6965ZM22.9114 19.6965C22.9114 19.6965 22.9114 19.6965 22.9114 19.6965L22.9406 20.1956L22.9114 19.6965ZM20.1154 7.73333V7.23333H19.6154H14.3077H13.8077V7.73333V9.66667V10.1667H14.3077H19.6154H20.1154V9.66667V7.73333ZM27.1923 13.5333V13.0333H26.6923H14.3077H13.8077V13.5333V15.4667V15.9667H14.3077H26.6923H27.1923V15.4667V13.5333ZM9.5 2.9C9.5 1.53185 10.5051 0.5 11.6538 0.5H25.9539L31.5 6.5605V26.1C31.5 27.4681 30.4948 28.5 29.3462 28.5H11.6538C10.5051 28.5 9.5 27.4681 9.5 26.1V2.9Z"
      fill="white"
      stroke="#054643"
    />
    <rect x="11" y="6" width="19" height="21" rx="2" fill="white" />
    <path d="M14.0625 22.875H18.3125V23.9375H14.0625V22.875Z" fill="#054643" />
    <path
      d="M22.0312 23.4062C21.9516 23.4062 21.8729 23.3883 21.8011 23.3538C21.7293 23.3193 21.6662 23.2691 21.6165 23.2069L19.6513 20.75H14.0625V19.6875H19.9062C19.9859 19.6875 20.0646 19.7054 20.1364 19.7399C20.2082 19.7744 20.2713 19.8246 20.321 19.8868L21.9924 21.9758L24.7767 17.799C24.8239 17.7284 24.8873 17.67 24.9616 17.6289C25.036 17.5878 25.1191 17.5651 25.204 17.5627C25.289 17.5603 25.3732 17.5784 25.4497 17.6153C25.5262 17.6523 25.5928 17.707 25.6437 17.775L27.0781 19.6875H28.9375V20.75H26.8125C26.73 20.75 26.6487 20.7308 26.5749 20.6939C26.5011 20.6571 26.437 20.6035 26.3875 20.5375L25.2441 19.0131L22.4731 23.1696C22.4268 23.2392 22.3648 23.2968 22.292 23.3378C22.2192 23.3788 22.1377 23.4021 22.0543 23.4056L22.0312 23.4062Z"
      fill="#054643"
    />
    <path
      d="M18.7812 16.4375V13.7812H19.3125C19.8759 13.7806 20.416 13.5565 20.8144 13.1582C21.2128 12.7598 21.4369 12.2196 21.4375 11.6562V10.0625H19.8438C19.2998 10.0634 18.7771 10.274 18.3845 10.6504C18.1094 10.151 17.7053 9.7344 17.2145 9.44413C16.7237 9.15385 16.164 9.00049 15.5938 9H14V10.5938C14.0009 11.4388 14.3371 12.2491 14.9346 12.8466C15.5322 13.4442 16.3424 13.7803 17.1875 13.7812H17.7188V16.4375H14V17.5H21.4375V16.4375H18.7812ZM19.8438 11.125H20.375V11.6562C20.3747 11.938 20.2627 12.208 20.0635 12.4072C19.8643 12.6064 19.5942 12.7185 19.3125 12.7188H18.7812V12.1875C18.7815 11.9058 18.8936 11.6357 19.0928 11.4365C19.292 11.2373 19.562 11.1253 19.8438 11.125ZM17.1875 12.7188C16.6241 12.7181 16.084 12.494 15.6856 12.0957C15.2872 11.6973 15.0631 11.1571 15.0625 10.5938V10.0625H15.5938C16.1571 10.0631 16.6973 10.2872 17.0957 10.6856C17.494 11.084 17.7181 11.6241 17.7188 12.1875V12.7188H17.1875Z"
      fill="#054643"
    />
  </svg>
</template>

<script>
export default {
  name: "SaisiesWidgetIcon",
};
</script>
