<template>
  <b-container fluid :class="`title-bar ${containerClass}`">
    <b-row no-gutters align-v="center">
      <b-col>
        <b-row align-v="center" align-h="between">
          <b-col class="tb-title" cols="12" md="auto">
            <slot>Sans titre</slot>
          </b-col>
          <!-- Si on a une barre d'onglets, on n'affiche pas la toolbar à sa droite... -->
          <template v-if="tabsParent">
            <div class="w-100"></div>
            <b-col class="tb-tabs" cols="12" md="auto">
              <TabsMenuComponent :parent="tabsParent"></TabsMenuComponent>
            </b-col>
          </template>
          <b-col v-else class="tb-toolbar" cols="12" md="auto">
            <slot name="toolbar">
              <b-button-toolbar class="float-right">
                <slot name="toolbar-content"></slot>
              </b-button-toolbar>
            </slot>
          </b-col>
        </b-row>
      </b-col>
      <!-- ... mais dans une colonne à part façon rowspan -->
      <b-col
        v-if="tabsParent"
        class="tb-toolbar mt-2 mt-md-0"
        cols="12"
        md="auto"
      >
        <slot name="toolbar">
          <b-button-toolbar class="float-right">
            <slot name="toolbar-content"></slot>
          </b-button-toolbar>
        </slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TabsMenuComponent from "./menus/TabsMenuComponent.vue";
export default {
  name: "TitleBar",
  components: { TabsMenuComponent },
  props: ["tabsParent"],
  computed: {
    containerClass() {
      return this.tabsParent ? "pt-1 pb-0 border-0" : "";
    },
  },
};
</script>
