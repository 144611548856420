<template>
  <b-container fluid :class="containerClasses">
    <b-row role="button" @click="toggle">
      <b-col cols="auto">
        <h2>
          <b-icon v-if="item.icone" :icon="item.icone"></b-icon>
          <component v-else :is="item.type.defaultIcon"></component>
        </h2>
      </b-col>
      <b-col class="px-0 px-md-3">
        <h2>{{ item.titre }}</h2>
      </b-col>
      <b-col cols="auto">
        <h2 v-if="visible">
          <b-icon-chevron-up></b-icon-chevron-up>
        </h2>
        <h2 v-else>
          <b-icon-chevron-down></b-icon-chevron-down>
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-collapse
          class="mx-2 mx-md-5 py-2 py-md-3"
          v-model="visible"
          @show="$emit('show')"
          @shown="$emit('shown')"
          @hide="$emit('hide')"
          @hidden="$emit('hidden')"
        >
          <slot></slot>
        </b-collapse>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ActualitesWidgetIcon from "../icons/ActualitesWidgetIcon.vue";
import ComptaWidgetIcon from "../icons/ComptaWidgetIcon.vue";
import ContactsWidgetIcon from "../icons/ContactsWidgetIcon.vue";
import DocumentsWidgetIcon from "../icons/DocumentsWidgetIcon.vue";
import IndicateursWidgetIcon from "../icons/IndicateursWidgetIcon.vue";
import LibreWidgetIcon from "../icons/LibreWidgetIcon.vue";
import SaisiesWidgetIcon from "../icons/SaisiesWidgetIcon.vue";
export default {
  name: "BaseWidget",
  components: {
    ActualitesWidgetIcon,
    ComptaWidgetIcon,
    ContactsWidgetIcon,
    DocumentsWidgetIcon,
    IndicateursWidgetIcon,
    LibreWidgetIcon,
    SaisiesWidgetIcon,
  },
  props: ["item", "expanded", "classes"],
  data: () => ({
    visible: false,
  }),
  computed: {
    containerClasses() {
      return `widget-frame p-2 p-md-4 ${this.classes ?? ""}`;
    },
  },
  created() {
    // TODO Intégrer ce paramétrage dans Strapi
    this.visible = this.expanded ?? !this.$store.getters["affichageMobile"];
  },
  methods: {
    show() {
      this.visible = true;
    },
    toggle() {
      this.visible = !this.visible;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>
