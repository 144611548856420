<template>
  <BaseWidget :item="item">
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <ContentSliderComponent :items="actus">
      <template v-slot="{ item }">
        <StrapiEvenementCard
          v-if="item.attributes.dateDebut"
          :item="item"
          :widget="true"
        ></StrapiEvenementCard>
        <StrapiActualiteCard
          v-else
          :item="item"
          :widget="true"
        ></StrapiActualiteCard>
      </template>
    </ContentSliderComponent>
    <template v-if="pages.length > 0">
      <b-button
        v-for="page in pages"
        :key="page.id"
        class="widget-button"
        pill
        variant="outline-primary"
        @click="$router.push({ name: 'page', params: { id: page.id } })"
      >
        {{ page.libelle }}
        <b-icon-arrow-right></b-icon-arrow-right>
      </b-button>
    </template>
  </BaseWidget>
</template>

<script>
import BaseWidget from "./BaseWidget.vue";
import ErrorComponent from "../ErrorComponent.vue";
import ContentSliderComponent from "../controls/ContentSliderComponent.vue";
import StrapiEvenementCard from "../strapi/StrapiEvenementCard.vue";
import StrapiActualiteCard from "../strapi/StrapiActualiteCard.vue";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ActualitesWidget",
  props: ["item"],
  components: {
    BaseWidget,
    ErrorComponent,
    ContentSliderComponent,
    StrapiEvenementCard,
    StrapiActualiteCard,
  },
  data() {
    return {
      actus: [],
      error: null,
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    pages() {
      let pages = [];
      if (this.item?.pageEvenements?.data) {
        pages.push({
          id: this.item.pageEvenements.data.id,
          libelle: "Voir les évènements",
        });
      }
      if (this.item?.pageActualites?.data) {
        pages.push({
          id: this.item.pageActualites.data.id,
          libelle: "Voir les actualités",
        });
      }
      return pages;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      try {
        let results = await Promise.all([
          StrapiService.getProchainsEvenements(
            this.exploitationCourante.codeSociete,
            this.item.limite
          ),
          StrapiService.getDernieresActualites(
            this.exploitationCourante.codeSociete,
            this.item.limite
          ),
        ]);
        this.actus = results[0].data.data
          .concat(results[1].data.data)
          .slice(0, this.item.limite);
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
  },
};
</script>
