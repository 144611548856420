<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="21" r="20" fill="#ACD1C6" />
    <path
      d="M19.2554 23.0059L19.2554 23.0059L19.2554 23.0059C19.2554 23.0059 19.2554 23.0059 19.2554 23.0059V23.0059Z"
      fill="white"
      stroke="#054643"
    />
    <path
      d="M18.8975 21.5102C18.7654 21.513 18.6411 21.5344 18.526 21.5682C17.9803 21.7284 17.5441 22.1939 17.3943 22.6849L17.2405 23.1893L16.745 23.0088L15.0665 22.3975L14.6211 22.2352L14.7594 21.7818C15.1995 20.3389 16.356 19.1613 17.7854 18.7416C19.1513 18.3406 20.6703 18.6536 21.9764 19.8431C22.2897 19.7626 22.6016 19.7146 22.9114 19.6965C22.9114 19.6965 22.9114 19.6965 22.9114 19.6965L22.9406 20.1956C23.8937 20.1397 24.7502 20.4025 25.4845 20.7367L18.8975 21.5102ZM18.8975 21.5102C18.6361 21.6859 18.411 21.8582 18.2232 22.0281C18.2233 22.028 18.2234 22.028 18.2235 22.0279L18.8975 21.5102ZM20.1154 7.73333V7.23333H19.6154H14.3077H13.8077V7.73333V9.66667V10.1667H14.3077H19.6154H20.1154V9.66667V7.73333ZM27.1923 13.5333V13.0333H26.6923H14.3077H13.8077V13.5333V15.4667V15.9667H14.3077H26.6923H27.1923V15.4667V13.5333ZM9.5 2.9C9.5 1.53185 10.5051 0.5 11.6538 0.5H25.9539L31.5 6.5605V26.1C31.5 27.4681 30.4948 28.5 29.3462 28.5H11.6538C10.5051 28.5 9.5 27.4681 9.5 26.1V2.9Z"
      fill="white"
      stroke="#054643"
    />
  </svg>
</template>

<script>
export default {
  name: "ComptaWidgetIcon",
};
</script>
