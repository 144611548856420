<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#ACD1C6" />
    <rect x="7" y="0" width="26" height="26" rx="2" fill="white" />
    <path d="M7 0H33V26H7V0" stroke="#054643" />
    <path
      d="M14 11V21"
      stroke="#054643"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M20 6V21"
      stroke="#054643"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M26 8V21"
      stroke="#054643"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IndicateursWidgetIcon",
};
</script>
