<template>
  <BaseWidget
    v-if="intervenants.length > 0 || contactsEnPlus.length > 0"
    :item="item"
    :expanded="true"
    classes="contact-widget"
  >
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <div class="clearfix">
      <div
        class="float-left mr-0 mr-md-3"
        v-for="contact in intervenants"
        :key="contact.id"
      >
        <StrapiContactComponent :item="contact"></StrapiContactComponent>
      </div>
      <div
        class="float-left mr-0 mr-md-3"
        v-for="contact in contactsEnPlus"
        :key="contact.id"
      >
        <StrapiContactComponent :item="contact"></StrapiContactComponent>
      </div>
    </div>
    <b-button
      v-if="item.page.data"
      class="widget-button"
      pill
      variant="outline-primary"
      @click="$router.push({ name: 'page', params: { id: item.page.data.id } })"
    >
      Voir tous les contacts
      <b-icon-arrow-right></b-icon-arrow-right>
    </b-button>
  </BaseWidget>
</template>

<script>
import BaseWidget from "./BaseWidget.vue";
import ErrorComponent from "../ErrorComponent.vue";
import StrapiContactComponent from "../strapi/StrapiContactComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ContactsWidget",
  props: ["item"],
  components: {
    BaseWidget,
    ErrorComponent,
    StrapiContactComponent,
  },
  data() {
    return {
      intervenants: [],
      error: null,
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    contactsEnPlus() {
      return this.item.contacts.data?.filter((c) => {
        // Filtrer tous les contacts qui figurent déjà dans les intervenants
        return !this.intervenants.find(
          (i) => i.attributes.email === c.attributes.email
        );
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      try {
        // Charger les contacts Strapi et les contacts Kerhis
        let result = await Promise.all([
          StrapiService.getContactsFromEmails(
            this.exploitationCourante.emailIntervenants
          ),
          ExploitationService.getIntervenants(
            this.exploitationCourante.codeTiers
          ),
        ]);

        // Partir des contacts Strapi et merger les contacts Kerhis
        let contacts = result[0].data.data.sort(
          UtilsService.sortByNumberProperty("attributes.ordre")
        );
        result[1].data.forEach((kerhis) => {
          let strapi = contacts.find(
            (c) => c.attributes.email === kerhis.email
          );
          if (strapi) {
            // Si contact Strapi existant, prendre les coordonnées Kerhis (plus à jour)
            if (kerhis.telephonePortable) {
              strapi.attributes.numeroPortable = kerhis.telephonePortable;
            }
            if (kerhis.telephoneFixe) {
              strapi.attributes.numeroFixe = kerhis.telephoneFixe;
            }
          } else {
            // Si pas de contact Strapi correspondant, utiliser celui de Kerhis
            contacts.push({
              id: kerhis.codeContact,
              attributes: {
                ordre: 999,
                avatar: null,
                nom: `${kerhis.prenom} ${kerhis.nom}`,
                fonction: "Technicien(ne)",
                description: "",
                email: kerhis.email,
                numeroPortable: kerhis.telephonePortable,
                numeroFixe: kerhis.telephoneFixe,
              },
            });
          }
        });

        // Affecter le résultat
        this.intervenants = contacts;
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
  },
};
</script>
